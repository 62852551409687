@charset "UTF-8";
/**
 * Styling specific to the input component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field, .wcux-input-container .wcux-input {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover, .wcux-input-container .wcux-input:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-input-container.wcux-input-multiline .wcux-input {
  height: unset; }

.wcux-input-container .wcux-input {
  padding: 0; }
  .wcux-input-container .wcux-input input,
  .wcux-input-container .wcux-input textarea {
    padding: 5px; }
    .wcux-input-container .wcux-input input:disabled,
    .wcux-input-container .wcux-input textarea:disabled {
      color: rgba(90, 90, 90, 0.5); }
  .wcux-input-container .wcux-input.wcux-input-readonly {
    background-color: rgba(0, 0, 0, 0.04); }
  .wcux-input-container .wcux-input.wcux-input-disabled {
    background-color: rgba(0, 0, 0, 0.04); }
  .wcux-input-container .wcux-input .wcux-adornment-icon-set {
    height: 100%;
    background-color: transparent; }
  .wcux-input-container .wcux-input.wcux-input-date.wcux-input-empty {
    color: #9f9f9f; }
  @supports (-webkit-overflow-scrolling: touch) {
    .wcux-input-container .wcux-input input[type='datetime-local'][readonly],
    .wcux-input-container .wcux-input input[type='date'][readonly],
    .wcux-input-container .wcux-input input[type='time'][readonly] {
      color: #1f1f1f;
      background-color: rgba(0, 0, 0, 0.04); }
    .wcux-input-container .wcux-input input[type='datetime-local'][disabled],
    .wcux-input-container .wcux-input input[type='date'][disabled],
    .wcux-input-container .wcux-input input[type='time'][disabled] {
      -webkit-appearance: none; } }

.wcux-input-container:focus-within .wcux-input {
  border-color: #9f9f9f;
  background-color: #ebebeb; }

.wcux-input-container.wcux-validation-error .wcux-input {
  border-bottom: 2px solid #f03040; }

.wcux-input-container.wcux-validation-error .wcux-validation-icon {
  color: #f03040; }

.wcux-input-container.wcux-validation-warning .wcux-input {
  border-bottom: 2px solid #ff7300; }

.wcux-input-container.wcux-validation-warning .wcux-validation-icon {
  color: #ff7300; }

.wcux-input-container.wcux-validation-confirmation .wcux-input {
  border-bottom: 2px solid #0ca919; }

.wcux-input-container.wcux-validation-confirmation .wcux-validation-icon {
  color: #0ca919; }

.wcux-input-container .wcux-input-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px; }
  .wcux-input-container .wcux-input-icon img,
  .wcux-input-container .wcux-input-icon svg {
    width: 16px;
    height: 16px; }

.wcux-input-container .wcux-input-message-container {
  display: flex;
  justify-content: space-between; }

.wcux-input-container .wcux-input-charcounter {
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px;
  padding-left: 5px; }

.wcux-input-container ::-webkit-calendar-picker-indicator {
  margin-left: 0; }
