@charset "UTF-8";
/**
 * Styling specific to the dialog component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-dialog-root {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 16px; }
  .wcux-dialog-root.wcux-dialog-complex-mode .wcux-dialog-content {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 16px;
    padding-bottom: 48px; }
  .wcux-dialog-root.wcux-dialog-complex-mode .wcux-dialog-header-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-bottom: #bababa 1px solid; }
  .wcux-dialog-root .wcux-dialog-ui-paper {
    min-height: 160px;
    min-width: 400px;
    border-top: 4px solid #1f1f1f; }
  .wcux-dialog-root .wcux-dialog-header,
  .wcux-dialog-root .wcux-dialog-content {
    padding-left: 32px;
    padding-right: 64px; }
  .wcux-dialog-root .wcux-dialog-header {
    font-family: inherit;
    color: #1f1f1f;
    font-weight: bold;
    padding-top: 28px;
    padding-bottom: 20px;
    display: flex;
    align-items: center; }
    .wcux-dialog-root .wcux-dialog-header .wcux-dialog-header-icon {
      display: flex;
      margin-right: 20px; }
  .wcux-dialog-root .wcux-dialog-content {
    color: #1f1f1f;
    min-height: 48px; }
    .wcux-dialog-root .wcux-dialog-content .wcux-form {
      margin: -15px; }
  .wcux-dialog-root .wcux-dialog-close-button {
    margin-left: auto;
    padding: 5px;
    position: absolute;
    right: 12px;
    top: 12px; }
    .wcux-dialog-root .wcux-dialog-close-button svg {
      color: #000000; }
  .wcux-dialog-root .wcux-dialog-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    padding-right: 8px; }
  .wcux-dialog-root .wcux-dialog-action-button {
    padding-left: 8px; }
    .wcux-dialog-root .wcux-dialog-action-button .wcux-button-small {
      min-width: 74px; }

[dir='rtl'] .wcux-dialog-root .wcux-dialog-close-button {
  right: auto;
  left: 12px; }

[dir='rtl'] .wcux-dialog-root .wcux-dialog-header,
[dir='rtl'] .wcux-dialog-root .wcux-dialog-content {
  padding-left: 64px;
  padding-right: 32px; }

[dir='rtl'] .wcux-dialog-root .wcux-dialog-header .wcux-dialog-header-icon {
  margin-left: 20px; }
