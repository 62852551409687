@charset "UTF-8";
/**
 * Styling specific to the About box component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-about-box-root {
  font-family: ABBvoice, Helvetica, sans-serif;
  color: #1f1f1f; }
  .wcux-about-box-root .wcux-about-box-ui-paper {
    height: 420px;
    width: 836px;
    padding: 0px 32px; }
  .wcux-about-box-root .wcux-about-box-header {
    height: 136px; }
    .wcux-about-box-root .wcux-about-box-header .wcux-about-box-line {
      background: #ff000f;
      height: 5px;
      width: 32px;
      margin-top: 35px; }
    .wcux-about-box-root .wcux-about-box-header .wcux-about-box-title {
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      margin-top: 16px; }
    .wcux-about-box-root .wcux-about-box-header .wcux-about-box-close-button {
      position: absolute;
      right: 32px;
      top: 16px;
      padding: 5px; }
      .wcux-about-box-root .wcux-about-box-header .wcux-about-box-close-button svg {
        color: #000000; }
  .wcux-about-box-root .wcux-about-box-content {
    height: 236px;
    font-size: 16px;
    overflow: auto; }
  .wcux-about-box-root .wcux-about-box-footer {
    height: 48px;
    padding-bottom: 8px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }

[dir='rtl'] .wcux-about-box-root .wcux-about-box-close-button {
  right: auto;
  left: 32px; }
