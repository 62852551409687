@charset "UTF-8";
/**
 * Styling specific to the Notification component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-notification-banner,
.wcux-notification-confirmation {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.35);
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 16px; }
  .wcux-notification-banner .wcux-notification-icon,
  .wcux-notification-confirmation .wcux-notification-icon {
    height: 24px;
    width: 24px; }

.wcux-notification-banner {
  min-width: 320px;
  height: 48px; }
  .wcux-notification-banner .wcux-notification-content {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .wcux-notification-banner .wcux-notification-content .wcux-notification-icon {
      margin: 12px; }
    .wcux-notification-banner .wcux-notification-content wcux-notification-close-button {
      height: 24px;
      width: 24px; }
    .wcux-notification-banner .wcux-notification-content .wcux-notification-close-icon {
      height: 16px;
      width: 16px; }

.wcux-notification-confirmation {
  min-width: 400px;
  padding: 20px 8px 8px 16px;
  border-top: 4px solid; }
  .wcux-notification-confirmation.wcux-notification-info {
    border-top-color: #3366ff; }
  .wcux-notification-confirmation.wcux-notification-success {
    border-top-color: #0ca919; }
  .wcux-notification-confirmation.wcux-notification-warning {
    border-top-color: #ff7300; }
  .wcux-notification-confirmation.wcux-notification-error {
    border-top-color: #f03040; }
  .wcux-notification-confirmation .wcux-notification-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 48px;
    margin-right: 56px; }
    .wcux-notification-confirmation .wcux-notification-content .wcux-notification-icon {
      margin-right: 20px; }
    .wcux-notification-confirmation .wcux-notification-content .wcux-notification-message {
      padding-top: 4px; }
  .wcux-notification-confirmation .wcux-notification-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px; }
    .wcux-notification-confirmation .wcux-notification-buttons .wcux-button-root {
      margin-left: 16px; }

.wcux-notification-normal {
  color: #ffffff; }
  .wcux-notification-normal.wcux-notification-info {
    background-color: #3366ff; }
  .wcux-notification-normal.wcux-notification-success {
    background-color: #0ca919; }
  .wcux-notification-normal.wcux-notification-warning {
    background-color: #ff7300; }
  .wcux-notification-normal.wcux-notification-error {
    background-color: #f03040; }
  .wcux-notification-normal .wcux-notification-icon {
    fill: #ffffff; }
  .wcux-notification-normal .wcux-notification-close-icon {
    fill: #ffffff; }

.wcux-notification-discreet {
  background-color: #ffffff;
  color: #1f1f1f; }
  .wcux-notification-discreet.wcux-notification-info .wcux-notification-icon {
    fill: #3366ff; }
  .wcux-notification-discreet.wcux-notification-success .wcux-notification-icon {
    fill: #0ca919; }
  .wcux-notification-discreet.wcux-notification-warning .wcux-notification-icon {
    fill: #ff7300; }
  .wcux-notification-discreet.wcux-notification-error .wcux-notification-icon {
    fill: #f03040; }
  .wcux-notification-discreet .wcux-notification-close-icon {
    fill: #848484; }

[dir='rtl'] .wcux-notification-confirmation {
  padding: 20px 16px 8px 8px; }
  [dir='rtl'] .wcux-notification-confirmation .wcux-notification-content {
    margin-left: 56px;
    margin-right: 0; }
    [dir='rtl'] .wcux-notification-confirmation .wcux-notification-content .wcux-notification-icon {
      margin-left: 20px;
      margin-right: 0; }
  [dir='rtl'] .wcux-notification-confirmation .wcux-notification-buttons .wcux-button-root {
    margin-left: 0;
    margin-right: 16px; }
