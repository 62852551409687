@charset "UTF-8";
/**
 * Styling specific to the toggle button component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-toggle-switch .MuiTypography-body1 {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #1f1f1f;
  line-height: 16px;
  margin-top: 5px; }
  .wcux-toggle-switch .MuiTypography-body1.Mui-disabled {
    opacity: 0.5;
    color: #5a5a5a; }

.wcux-toggle-switch .MuiFormControlLabel-root {
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px; }

.wcux-toggle-switch .wcux-toggle-switch-root {
  width: 48px;
  height: 24px;
  padding: 0px;
  margin-right: 8px;
  display: flex; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled .MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: #bababa;
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #3366ff;
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled .MuiIconButton-root svg {
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled .MuiSwitch-switchBase.Mui-checked svg {
    fill: #3366ff;
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled:hover .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #3366ff;
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-disabled:hover .MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: #bababa;
    opacity: 0.5; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-readonly .MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: #1f1f1f;
    opacity: 1; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-readonly .MuiIconButton-root {
    background-color: #1f1f1f; }
    .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-readonly .MuiIconButton-root svg {
      color: #ffffff; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-readonly:hover .MuiIconButton-root {
    background-color: #1f1f1f; }
  .wcux-toggle-switch .wcux-toggle-switch-root.wcux-switch-readonly:hover .MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: #1f1f1f; }
  .wcux-toggle-switch .wcux-toggle-switch-root .wcux-toggle-switch-base {
    padding: 0px;
    transition-duration: 0.5s; }
  .wcux-toggle-switch .wcux-toggle-switch-root .wcux-toggle-switch-base.checked {
    transform: translateX(120%);
    color: #ffffff; }
  .wcux-toggle-switch .wcux-toggle-switch-root .MuiIconButton-root {
    background-color: #ffffff;
    height: 20px;
    width: 20px;
    margin: 2px; }
    .wcux-toggle-switch .wcux-toggle-switch-root .MuiIconButton-root svg {
      width: 16px;
      height: 16px;
      color: #1f1f1f; }
  .wcux-toggle-switch .wcux-toggle-switch-root .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track:not(.Mui-disabled) {
    background-color: #3366ff;
    opacity: 1; }
  .wcux-toggle-switch .wcux-toggle-switch-root .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) svg {
    fill: #3366ff; }
  .wcux-toggle-switch .wcux-toggle-switch-root .wcux-toggle-switch-thumb {
    width: 12px;
    height: 12px; }
  .wcux-toggle-switch .wcux-toggle-switch-root .wcux-toggle-switch-track {
    opacity: 1;
    background-color: #bababa;
    border-radius: 12px;
    border: 0px solid #ebebeb; }
  .wcux-toggle-switch .wcux-toggle-switch-root:hover .wcux-toggle-switch-track:not(.Mui-disabled) {
    background-color: #9f9f9f; }
  .wcux-toggle-switch .wcux-toggle-switch-root:hover .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track:not(.Mui-disabled) {
    background-color: #2a35ff; }
  .wcux-toggle-switch .wcux-toggle-switch-root:hover .MuiIconButton-root:not(.Mui-disabled) {
    background-color: #ffffff; }
  .wcux-toggle-switch .wcux-toggle-switch-root:hover .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) svg {
    fill: #2a35ff; }
  .wcux-toggle-switch .wcux-toggle-switch-root:active .wcux-toggle-switch-track:not(.Mui-disabled) {
    background-color: #848484; }
  .wcux-toggle-switch .wcux-toggle-switch-root:active .MuiIconButton-root:not(.Mui-disabled) svg {
    fill: #1f1f1f; }
  .wcux-toggle-switch .wcux-toggle-switch-root:active .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track:not(.Mui-disabled) {
    background-color: #2c20d4; }
  .wcux-toggle-switch .wcux-toggle-switch-root:active .MuiIconButton-root:not(.Mui-disabled) {
    background-color: #ffffff; }
  .wcux-toggle-switch .wcux-toggle-switch-root:active .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) svg {
    fill: #2c20d4; }

[dir='rtl'] .wcux-toggle-switch .wcux-toggle-switch-root {
  margin-left: 8px;
  margin-right: 0; }
