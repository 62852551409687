@charset "UTF-8";
/**
 * Styling specific to the launch screen
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-launch-screen {
  /* Minimal CSS Reset */
  box-sizing: border-box;
  /* End minimal CSS Reset */
  height: 100%;
  width: 100%;
  background-size: cover;
  font-family: ABBvoice, Helvetica, sans-serif;
  color: #1f1f1f; }
  .wcux-launch-screen *,
  .wcux-launch-screen *:before,
  .wcux-launch-screen *:after {
    box-sizing: inherit; }
  .wcux-launch-screen body,
  .wcux-launch-screen h1,
  .wcux-launch-screen h2,
  .wcux-launch-screen h3,
  .wcux-launch-screen h4,
  .wcux-launch-screen h5,
  .wcux-launch-screen h6,
  .wcux-launch-screen p,
  .wcux-launch-screen ol,
  .wcux-launch-screen ul {
    margin: 0;
    padding: 0;
    font-weight: normal; }
  .wcux-launch-screen img {
    max-width: 100%;
    max-height: 100%; }
  .wcux-launch-screen .wcux-launch-screen-scrollable-container {
    max-height: calc(100% - 85px);
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 85px; }
    .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section {
      padding: 25px 55px; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-product-version {
        text-align: center;
        font-size: 14px; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-abb-icon {
        height: 45px;
        padding: 0 0 0 20px;
        float: right; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-line {
        background: #ff000f;
        display: block;
        height: 5px;
        width: 32px;
        font-weight: bold; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-product-name {
        font-size: 32px;
        margin-top: 16px;
        font-weight: bold; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-sub-product-name {
        font-size: 20px;
        margin-top: 10px; }
    .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-middle-section {
      margin-left: 55px;
      margin-right: 55px;
      min-height: 25px; }
      .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-middle-section .wcux-launch-screen-content {
        width: 100%;
        margin: 10px 0; }
  .wcux-launch-screen .wcux-launch-screen-bottom-section {
    margin-left: 55px;
    margin-right: 55px;
    bottom: 0;
    width: calc(100% - 110px);
    margin-bottom: 25px;
    position: fixed; }
    .wcux-launch-screen .wcux-launch-screen-bottom-section .wcux-launch-screen-copyright {
      font-size: 14px;
      text-align: right;
      margin-top: 5px; }
    .wcux-launch-screen .wcux-launch-screen-bottom-section .wcux-launch-screen-legal {
      margin-top: 20px;
      font-size: 12px;
      text-align: justify; }

[dir='rtl'] .wcux-launch-screen .wcux-launch-screen-scrollable-container .wcux-launch-screen-upper-section .wcux-launch-screen-abb-icon {
  padding: 0 20px 0 0;
  float: left; }
