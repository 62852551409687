@charset "UTF-8";
/**
 * Styling specific to the loading indicator component.
 * Styling inspired by SpinKit "wave" spinner.
 * SpinKit version 1.2.5 (https://github.com/tobiasahlin/SpinKit)
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-loading-indicator-root {
  margin: 0px auto;
  width: 60px;
  height: 50px; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect {
    margin: 0px 2px;
    height: 100%;
    width: 8px;
    display: inline-block;
    animation: wcux-loading-indicator-animation 1.2s infinite ease-in-out; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect1 {
    animation-delay: -1.2s; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect2 {
    animation-delay: -1.1s; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect3 {
    animation-delay: -1s; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect4 {
    animation-delay: -0.9s; }
  .wcux-loading-indicator-root .wcux-loading-indicator-rect5 {
    animation-delay: -0.8s; }
  .wcux-loading-indicator-root.wcux-loading-indicator-small {
    width: 30px;
    height: 25px; }
    .wcux-loading-indicator-root.wcux-loading-indicator-small .wcux-loading-indicator-rect {
      margin: 0px 1px;
      width: 4px; }
  .wcux-loading-indicator-root.wcux-loading-indicator-large {
    width: 90px;
    height: 75px; }
    .wcux-loading-indicator-root.wcux-loading-indicator-large .wcux-loading-indicator-rect {
      margin: 0px 3px;
      width: 12px; }

@keyframes wcux-loading-indicator-animation {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    background-color: #e0f0ff; }
  20% {
    transform: scaleY(1);
    background-color: #3366ff; } }
