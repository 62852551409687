@charset "UTF-8";
/**
 * Styling specific to the dropdown component
 * © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field, .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover, .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-dropdown-root .wcux-dropdown-action-container {
  display: flex; }
  .wcux-dropdown-root .wcux-dropdown-action-container .MuiButton-root {
    padding: 0px !important; }
  .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-action-button {
    padding-left: 10px; }
  .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-highlighter {
    background-color: #9ebff7; }
  .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container {
    flex: 1; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__dropdown-indicator,
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__clear-indicator {
      padding-right: 4px;
      padding-left: 4px; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__dropdown-indicator svg,
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__clear-indicator svg {
        width: 16px;
        height: 16px; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__menu {
      border-radius: 0;
      hyphens: auto;
      margin-top: 0;
      word-wrap: break-word; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__menu-list {
      padding: 0; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option {
      font-family: ABBvoice, Helvetica, sans-serif;
      font-size: 14px;
      color: #1f1f1f;
      cursor: pointer; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option:hover {
        background-color: #ebebeb; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option--is-selected {
      background: transparent; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option--is-selected:hover {
        background: #ebebeb; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option--is-focused {
      background-color: #ebebeb; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__option--is-focused:hover {
        background-color: #ebebeb; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value {
      border-radius: 12px;
      background-color: #dbdbdb;
      height: 22px;
      display: flex;
      padding-left: 6px;
      margin: 2px 8px 2px 0;
      -webkit-box-align: center;
      align-items: center; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__label {
      margin-right: 4px; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__remove {
      border-radius: 50%;
      margin-right: 4px;
      padding: 1px; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__remove svg {
        height: 14px;
        width: 14px; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__remove:hover {
        background-color: rgba(132, 132, 132, 0.3);
        color: #1f1f1f; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control {
      cursor: pointer;
      height: auto; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-multiselect-container .wcux-dropdown__control {
      height: unset; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control--is-focused, .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control--is-focused:hover {
      border-color: #3366ff; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__control--is-disabled {
      background-color: rgba(0, 0, 0, 0.04); }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__single-value,
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__label {
      color: #1f1f1f; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__single-value--is-disabled {
      color: rgba(90, 90, 90, 0.5); }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value--is-disabled {
      opacity: 0.5; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-readonly .wcux-dropdown__single-value--is-disabled {
      color: #1f1f1f; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-readonly .wcux-dropdown__multi-value--is-disabled {
      opacity: unset; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-readonly .wcux-dropdown__multi-value--is-disabled .wcux-dropdown__multi-value__label {
        margin-right: 6px;
        padding-left: 3px; }
      .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-readonly .wcux-dropdown__multi-value--is-disabled .wcux-dropdown__multi-value__remove {
        display: none; }
    .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container.wcux-dropdown-readonly .wcux-dropdown__indicators {
      display: none; }

.wcux-dropdown-root.wcux-validation-error .wcux-dropdown-container .wcux-dropdown__control {
  border-bottom: 2px solid #f03040; }

.wcux-dropdown-root.wcux-validation-error .wcux-validation-icon {
  color: #f03040; }

.wcux-dropdown-root.wcux-validation-warning .wcux-dropdown-container .wcux-dropdown__control {
  border-bottom: 2px solid #ff7300; }

.wcux-dropdown-root.wcux-validation-warning .wcux-validation-icon {
  color: #ff7300; }

.wcux-dropdown-root.wcux-validation-confirmation .wcux-dropdown-container .wcux-dropdown__control {
  border-bottom: 2px solid #0ca919; }

.wcux-dropdown-root.wcux-validation-confirmation .wcux-validation-icon {
  color: #0ca919; }

[dir='rtl'] .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-action-button {
  padding-left: 0;
  padding-right: 10px; }

[dir='rtl'] .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value {
  margin: 2px 0 2px 8px;
  padding-left: 0;
  padding-right: 6px; }

[dir='rtl'] .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__label {
  padding-left: 3px;
  padding-right: 6px; }

[dir='rtl'] .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__label,
[dir='rtl'] .wcux-dropdown-root .wcux-dropdown-action-container .wcux-dropdown-container .wcux-dropdown__multi-value__remove {
  margin-right: 0;
  margin-left: 4px; }
