@charset "UTF-8";
/**
 * Styling specific to the ReadOnlyTable component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-readonly-table {
  width: 100%;
  background-color: #ffffff; }
  .wcux-readonly-table .wcux-header-root {
    padding: 0 16px; }
  .wcux-readonly-table .wcux-paper-root {
    overflow-x: auto; }
  .wcux-readonly-table .wcux-readonly-table-head-root {
    height: 20px;
    text-transform: uppercase;
    border-top-width: 3px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-color: #1f1f1f;
    border-style: solid;
    font-family: ABBvoice, Helvetica, sans-serif;
    font-size: 12px; }
    .wcux-readonly-table .wcux-readonly-table-head-root .MuiTableCell-head {
      color: #686868;
      font-family: ABBvoice, Helvetica, sans-serif;
      font-size: 12px; }
    .wcux-readonly-table .wcux-readonly-table-head-root .MuiTableCell-root {
      border-bottom: 1px solid #1f1f1f; }
  .wcux-readonly-table .wcux-readonly-table-cell-root {
    font-family: ABBvoice, Helvetica, sans-serif;
    font-size: 14px;
    color: #1f1f1f;
    text-align: start; }
  .wcux-readonly-table .wcux-readonly-table-cell-head {
    text-align: start;
    padding: 8px 8px 8px 16px;
    line-height: 1rem; }
  .wcux-readonly-table .wcux-readonly-table-title-container {
    display: flex;
    align-items: center; }
  .wcux-readonly-table .MuiTableCell-alignRight {
    text-align: right !important; }
  .wcux-readonly-table .MuiTableCell-alignCenter {
    text-align: center !important; }
  .wcux-readonly-table .MuiTableCell-alignLeft {
    text-align: left !important; }
  .wcux-readonly-table .wcux-readonly-table-image {
    height: 30px;
    width: 30px;
    margin-right: 5px; }
  .wcux-readonly-table .wcux-readonly-table-title {
    font-family: ABBvoice, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold; }
  .wcux-readonly-table .wcux-readonly-table-action-button {
    margin: 0 5px; }
  .wcux-readonly-table .wcux-readonly-table-button-spacer {
    width: 5px;
    display: inline-block; }
  .wcux-readonly-table.wcux-validation-error .wcux-readonly-table-root {
    border-bottom: 3px solid #f03040; }
  .wcux-readonly-table.wcux-validation-error .wcux-validation-icon {
    color: #f03040; }

[dir='rtl'] .wcux-readonly-table .wcux-readonly-table-image {
  margin-left: 5px;
  margin-right: 0; }
