@charset "UTF-8";
/**
 * Styling specific to the Tree component
 * © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
@font-face {
  font-family: 'ABBvoice';
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/ABBvoice_W_Rg.woff") format("woff");
  /* Pretty Modern Browsers */ }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: ABBvoice, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-tree {
  font-family: ABBvoice, Helvetica, sans-serif;
  height: 100%; }
  .wcux-tree .rst__lineHalfHorizontalRight::before,
  .wcux-tree .rst__lineFullVertical::after,
  .wcux-tree .rst__lineHalfVerticalTop::after,
  .wcux-tree .rst__lineHalfVerticalBottom::after,
  .wcux-tree .rst__lineChildren::after {
    background-color: #bababa; }
  .wcux-tree .rst__expandButton,
  .wcux-tree .rst__collapseButton {
    border-radius: 2px; }
  .wcux-tree .rst__expandButton {
    background: #dbdbdb url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgcng9IjEuNSIgZmlsbD0iI0RCREJEQiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0IDhIMTBWNEg4VjhINFYxMEg4VjE0SDEwVjEwSDE0VjhWOFoiIGZpbGw9IiMxRjFGMUYiLz4KPC9zdmc+Cg==") no-repeat center; }
  .wcux-tree .rst__collapseButton {
    background: #dbdbdb url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgcng9IjEuNSIgZmlsbD0iI0RCREJEQiIgc3Ryb2tlPSJibGFjayIvPgo8cmVjdCB4PSI0IiB5PSI4IiB3aWR0aD0iMTAiIGhlaWdodD0iMiIgZmlsbD0iIzFGMUYxRiIvPgo8L3N2Zz4K") no-repeat center; }
  .wcux-tree .rst__rowWrapper {
    padding: 4px 10px 4px 0; }
  .wcux-tree .rst__row {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px; }
    .wcux-tree .rst__row.wcux-tree-node-selectable {
      cursor: pointer; }
    .wcux-tree .rst__row.wcux-tree-node-selected {
      border: 1px solid #3366ff; }
      .wcux-tree .rst__row.wcux-tree-node-selected .rst__moveHandle,
      .wcux-tree .rst__row.wcux-tree-node-selected .rst__rowContents {
        background-color: #c5e0fa; }
    .wcux-tree .rst__row.wcux-tree-node-has-buttons .rst__rowToolbar {
      border-left: 1px solid #bababa; }
    .wcux-tree .rst__row .rst__moveHandle {
      background: #ffffff url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iNyIgd2lkdGg9IjE3IiBoZWlnaHQ9IjIiIGZpbGw9IiM5RjlGOUYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxOUg2TDkgMjMuNUwxMiAxOVoiIGZpbGw9IiM5RjlGOUYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDQuNUgxMkw5IDBMNiA0LjVaIiBmaWxsPSIjOUY5RjlGIi8+CjxyZWN0IHk9IjExIiB3aWR0aD0iMTciIGhlaWdodD0iMiIgZmlsbD0iIzlGOUY5RiIvPgo8cmVjdCB5PSIxNSIgd2lkdGg9IjE3IiBoZWlnaHQ9IjIiIGZpbGw9IiM5RjlGOUYiLz4KPC9zdmc+") no-repeat center;
      box-shadow: none;
      border-radius: 5px 0 0 5px; }
    .wcux-tree .rst__row .rst__loadingHandle {
      background-color: #ffffff;
      box-shadow: none;
      border-radius: 5px 0 0 5px; }
      .wcux-tree .rst__row .rst__loadingHandle .rst__loadingCircle {
        width: 60%;
        height: 60%;
        margin: 20%; }
        .wcux-tree .rst__row .rst__loadingHandle .rst__loadingCircle .rst__loadingCirclePoint::before {
          background-color: #3366ff; }
    .wcux-tree .rst__row .rst__rowContents {
      box-shadow: none;
      border-radius: 0 5px 5px 0;
      padding-right: 0px; }
      .wcux-tree .rst__row .rst__rowContents.rst__rowContentsDragDisabled {
        border-radius: 5px; }
      .wcux-tree .rst__row .rst__rowContents .rst__rowToolbar {
        border-radius: 0 5px 5px 0;
        background-color: #ebebeb;
        height: 100%;
        align-items: center; }
        .wcux-tree .rst__row .rst__rowContents .rst__rowToolbar .wcux-button-icon,
        .wcux-tree .rst__row .rst__rowContents .rst__rowToolbar .wcux-button-icon svg {
          width: 16px;
          height: 16px; }
